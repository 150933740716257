<template>
  <div id="department">
    <div class="title">診療科一覧</div>
    <div class="departs">
      <div v-for="item in departList" class="depart" :style="getStyle(item)" @click="goTo(item.url)">
        <div class="name">{{item.name}}</div>
        <div class="divider"></div>
        <div class="name-en">{{item["name-en"]}}</div>
      </div>
    </div>
  </div>
  </template>
  
  <script>
  export default {
    name: "MDeparts",
    data() {
      return {
        departList: [
          // { 'name': '胃腸科', 'name-en': 'Gastroenterology', 'color': '#E88383', 'url': 'internal' },
          // { 'name': '漢方内科', 'name-en': 'Kampo Medicine', 'color': '#E5D05E', 'url': 'kampo' },
          // { 'name': '腫瘍外科', 'name-en': 'Surgical Oncology', 'color': '#91C360', 'url': 'dermatology'  },
          { 'name': '一般内科', 'name-en': 'General Medicine', 'color': '#E88383', 'url': 'generalMedicine' },
          { 'name': '発熱外来', 'name-en': 'Fever Outpatient', 'color': '#e5d05e', 'url': 'fever'  },
          { 'name': 'コロナ予防接種', 'name-en': 'Corona Vaccination', 'color': '#62b6e2', 'url': 'corona'  },
          { 'name': 'メディカルダイエット', 'name-en': 'Reduce Weight', 'color': '#91c360', 'url': 'diet'  },
          { 'name': 'プラセンタ', 'name-en': 'Placenta', 'color': '#70c9b5', 'url': 'placenta'  },
          // { 'name': 'セカンドオピニオン', 'name-en': 'Second Opinion', 'color': '#70C9B5', 'url': 'second'  },
          { 'name': 'エクソソーム', 'name-en': 'Exosome Infusion', 'color': '#7887d9', 'url': 'stemsup'  },
        ]
      }
    },
    methods: {
      getStyle(item) {
        return `--main_color:${item.color}`
      },
      goTo(path) {
        // 先隐藏オンライン診療
        if (path !== 'telemedicine') {
          if( path == 'ReduceWeight'){
            window.open('https://www.tenyukai-hope.com','_self')
          }else{
            this.$router.push(`/department/${path}`)
          }
        }
        
      }
    }
  }
  </script>
  
  <style scoped>
    
  #department {
    margin: 4rem 1rem;
  }
  
  .title {
    text-align: center;
    color: #333;
    letter-spacing: 3px;
    margin-bottom: 2rem;
  }
  .departs {
    --main_color: #535353;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 10px;
    grid-column-gap: 10px;
  }
  .departs .depart {
    
    height: 100px;
    border-radius: 10px;
    font-size: 14px;
    background-color: var(--main_color);
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: 50ms;
  }
  .departs .depart .name-en {
    font-size: 12px;
    text-align: center;
    width: 150%;
    transform: scale(0.7);
    transition: 50ms;
  }
  .departs .depart .divider {
    margin: 6px 0;
    width: 80%;
    border-top: 1px solid #FFFFFF;
    transition: 50ms;
  }
  
  .departs .depart:active{
    color: var(--main_color);
    background-color: #ffffff;
    font-size: 12px;
  }
  .departs .depart:active .name-en {
    font-size: 10px;
  }
  .departs .depart:active .divider {
    border-top: 1px solid var(--main_color);
    width: 70%;
  }
  </style>
  