<template>
  <div id="internal">
    
    <div class="hidden-sm-and-down">
      <PageHeader/>
    </div>
    <div class="hidden-md-and-up">
      <m-top/>
    </div>

    <div class="hidden-xs-only">
      <el-row type="flex" justify="center" >
        <el-col :md="20" :lg="18" :xl="15" >
          <div style="width:100%;max-width: 1284px; margin: 0 auto;">
          <div class="side-box" style="margin-bottom: 30px;">
            <div class="title">がん治療</div>
  
          </div>
  
          
  
          <div class="separator" >


            <div class="bg_black" style="height: auto; width: 100%;" >
              <div class="side-box-img" style="width: 100%; height: 550px;">
                <div class="content1" style="width: 60%;">
                  <div class="side-box">
                    <div class="side-text" style="width: 90%;">
                      <div class="side-box">
                        <div class="side-line-highlight"></div>

                        <div class="title1">医学博士<br>白川 太郎先生</div>

                      </div>
                      <div class="content_text">
                        <div class="text1">京都大学医学部卒業後、オックスフォード大学、大阪大学で研究を重ねました。</div>
                        <div class="text1">オックスフォード大学医学部呼吸器科講師、ウェールズ大学医学部大学院実験医学部門助教授、中国第４軍医科大学付属西京医院呼吸器科客員教授、南京医科大学国際鼻アレルギーセンター分子アレルギー学部門客員教授などを経て、２０００年に京都大学大学院医学研究科教授に就任しました。</div>
                        <div class="text1">２００１年理化学研究所遺伝子多型研究センター、アレルギー体質関連遺伝子研究チームリーダー（非常勤）兼務などを経て、２００８年統合医療をメインとしたクリニックを開設し、院長に就任しました。
                          ２０１８年からは、天裕会「のぞみクリニック」のがん治療専門医を兼務し、精力的に活動中です。</div>
                      </div>
                    </div>
                    
                  </div>
                </div>
                <div style="width: 40%; height: 100%;">
                <img src="../../assets/departments/cancer/医生01.png" style="width: 100%;height: 100%;object-fit: cover;">
              </div>
              </div>
              
            </div>
            <div class="bg_black">
              <div class="seperate_line"></div>
              <div class="content1" style="padding-bottom: 30px;">
                <div class="side-box-img" style="width: 100%;">
                    <div class="side-text" style="width: 50%;">
                        <div class="side-box">
                          <div class="side-line-highlight"></div>

                          <div class="title1">がんは怖くない</div>

                        </div>
                        <div class="content_text">
                          <div class="text1">日本で著名な末期がん治療専門医である白川太郎医師は、「ヨウ素水」を用いた総合療法で注目を集めています。</div>
                          <div class="text1">この療法により、世界の末期がん患者の平均3年生存率が15%であるのに対し、白川医師の治療を受けた患者の3年生存率は60%にまで向上しています。</div>
                          <div class="text1">この結果は、末期がん患者に対する希望を与える大きな成果とされています。現在までに、ヨウ素水を用いた総合治療を受けた患者は300例以上に達しています。</div>
                        </div>
                    </div>
                  <div class="side-img"style="width: 50%;">
                    <div class="side-box" style="width: 100%; justify-content: space-around;">
                      <img src="../../assets/departments/cancer/著作01.jpg" style="width: 200px;">
                      <img src="../../assets/departments/cancer/著作02.jpg" style="width: 200px;">
                    </div>
                    <div class="content1">
                      <div class="text1" style="text-align: center;">白川太郎医師の著作の一部</div>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
            
            <div class="side-box" style="height: 150px; width: 100%; overflow: hidden;">
              <div style="position:absolute;width: 80%;left: 10%;">
                <div class=" slogan_large font_border">HOPE HIKARI（ヨウ素水）</div>
              </div>
            </div>

            <div class="img_banner">
              <img style="width: 100%;" src="../../assets/departments/cancer/IMG_0054.png">               
            </div>

            <div class="bg_green" style="padding-top: 30px; padding-bottom: 30px;">
              <div class="side-text">
                <div class="slogan_big_l" style="color:#CBB8B2;">
                  HOPE HIKARI（ヨウ素水）について
                </div>
              </div>

              <div class="content1">
                <div class="side-text">
                  <div class="text1">「HOPE HIKARI」は、白川太郎医師と株式会社ユーアイナの共同研究と開発に基づくもので、がん治療において特別な効果が期待されるヨウ素水です。</div>
                </div>
              </div>
              
              <div class="content1">
                <div class="side-text">
                  
                  <div>
                    <div class="side-img" style="width: 40%;float: right; margin-right: 0px">
                      <img src="../../assets/departments/cancer/碘元素作用01.webp" class="fit_img" >
                    </div>
                    <div class="side-box">
                      <div class="side-line-highlight"></div>

                      <div class="title1">HOPE HIKARIの抗がんメカニズム</div>

                    </div>
                    <div class="content_text" style="width: 60%;">
                      <div class="text1">１、直接作用：ヨウ素の高い酸化還元特性を利用して、ミトコンドリア介在のアポトーシスを誘発する。</div>
                      <div class="text1">・ミトコンドリア膜電位の破壊：ヨウ素が細胞に入り込むと、電子（e-）を放出し、がん細胞のミトコンドリア膜電位を破壊することで作用します。ミトコンドリア膜電位の低下は、細胞アポトーシスの重要なステップです。ミトコンドリア膜電位が低下すると、ミトコンドリアはアポトーシス促進因子を放出し、これがカスパーゼ経路を活性化し、最終的にがん細胞のアポトーシスを引き起こします。</div>
                      <div class="text1">・抗酸化作用：ヨウ素の抗酸化作用は、がん細胞が生成する過剰な活性酸素を除去し、局所的な微小環境の炎症を改善するのに役立ちます。また、正常な細胞を酸化ストレスから保護し、同時にがん細胞がアポトーシスに入ることを促進します。</div>
                    </div>
                    
                    <div class="content_text" >
                      <div class="text1">２、間接作用：細胞核信号伝達経路の調節</div>
                      <div class="text1">・6-ヨウ素内エステル（6-IL）の形成：ヨウ素は膜脂質であるアラキドン酸と反応して6-ヨウ素内エステル（6-IL）を形成します。これは抗腫瘍活性を持つ化合物です。6-ILは、腫瘍抑制遺伝子であるp53やp21の発現を誘導することができ、これらの遺伝子は細胞周期の調節に関与し、細胞分裂の停止や細胞のアポトーシスを誘導します。さらに重要なのは、6-ILがPPARγ（過酸化物質増殖物質活性化受容体γ）と結合してその機能を活性化することです。</div>
                      <div class="text1">・PPARγの活性化：PPARγは、細胞の代謝、分化、そしてアポトーシスにおいて非常に重要な役割を果たす核受容体です。活性化されたPPARγは、細胞の分化や増殖を抑制し、細胞のアポトーシスを促進するだけでなく、がんの上皮-間質転化（EMT）を阻止することもできます。</div>
                      <div class="text1">説明: 上皮-間質転化（Epithelial-Mesenchymal Transition, EMT）は、がん細胞が浸潤性と転移能力を獲得するための重要なプロセスです。EMTは、がん細胞が原発腫瘍部位から移動して他の組織に侵入することを可能にするだけでなく、さまざまな能力を与え、治療に対する抵抗性を高めます。</div>
                  
                    </div>

                    
                  </div>
                  

                </div>

                
              </div>


              <div class="content1">
                <div class="side-text">
                  <div class="side-box-img">
                    <div class="content_text" style="width: 60%;" >
                      <div class="text1">３、特異性：がん細胞への選択的な毒性</div>
                        <div class="text1">・正常細胞に対する低毒性：がん細胞は正常細胞に比べてアラキドン酸を高濃度で含んでいるため、ヨウ素の強力な抗がん活性は、膜脂質アラキドン酸と反応して6-ILを形成し、PPARγの機能を活性化することによって発揮されます。</div>
                        <div class="text1">このため、ヨウ素水は高い選択性を持ち、腫瘍細胞のEMT（上皮間葉転換）を阻止し、がん細胞のアポトーシス（細胞死）を誘導する一方で、正常細胞にはダメージを与えません。また、ヨウ素は人体の代謝においても重要な元素の一つであり、安定したヨウ素の摂取が安全かつ有効であることが示されています。この広範な適用性により、ヨウ素は非常に有望な抗がん剤として注目されています。</div>
                      
                    </div>
                    <div class="side-img" style="width: 40%;margin-right: 0px;">
                      <img src="../../assets/departments/cancer/碘元素作用02.webp" class="fit_img">
                    </div>
                  </div>
                </div>
              </div>

              <div class="content1">
                <div class="side-text" >
                  <div class="side-box">
                    <div class="side-line-highlight"></div>

                    <div class="title1">未承認医薬品</div>

                  </div>
                  <div class="content_text">
                    <div class="text1">HOPE HIKARI（ヨウ素水）は日本で医薬品医療機器等法上の承認を得ていない未承認医薬品です。</div>
                    <div class="text1">日本の薬事法では、一般的な医薬品が市場に流通するには、薬品承認を取得し、大規模な薬理試験を実施した後に販売される必要があります</div>
                    <div class="text1">しかし、市場に流通せず、特定の病院での治療や研究目的に限って使用される場合は、薬事法の制限を受けません。医療資格のある病院は、薬品の原材料、製造説明書、用途、治療方法および安全性について厚生省や保健所に報告するだけでよいです。各製造ロットごとに指定された機関の検査を受け、合格した場合にのみ院内処方や患者への使用が可能となります。また、使用対象者の名前や連絡先などの情報は、毎月1回保健所に報告する必要があります。薬品の品質に関する責任は、使用する病院およびその病院の主治医が負います。厚生省と保健所には監督と指導の責任があります。</div>
                    <div class="text1">現在、HOPE　HIKARI（ヨウ素水）は抗がん剤の新星として、その高い効果、低副作用、高い耐性により多くの患者の注目を集めています。日本独自の医療および薬品製造制度は、薬品の安全性と新薬開発の活発さを確保しています。</div>
                  </div>
                </div>
              </div>
              
            </div>

          </div>
          </div>
  
  
  
        </el-col>
      </el-row>
      <PageFooter/>
    </div>
  
    <div class="hidden-sm-and-up">
      <div class="side-box" style="margin-bottom: 30px;">
              <div class="title">がん治療</div>
    
      </div>
      <div class="separator" >
        <div class="bg_black" style="padding-bottom: 30px; padding-top: 18px;" >
          <div style="width: 100%; height: fit-content;">
            <div>
              <div class="side-box">
                <div class="m_side-text">
                  <div class="side-box">
                    <div class="side-line-highlight"></div>

                    <div class="title1">医学博士<br>白川 太郎先生</div>

                  </div>
                  <div style="width: 100%;">
                    <img src="../../assets/departments/cancer/医生01.png" style="width: 100%;height: 100%;object-fit: cover;">
                  </div>
                  <div class="content_text">
                    <div class="text1">京都大学医学部卒業後、オックスフォード大学、大阪大学で研究を重ねました。</div>
                    <div class="text1">オックスフォード大学医学部呼吸器科講師、ウェールズ大学医学部大学院実験医学部門助教授、中国第４軍医科大学付属西京医院呼吸器科客員教授、南京医科大学国際鼻アレルギーセンター分子アレルギー学部門客員教授などを経て、２０００年に京都大学大学院医学研究科教授に就任しました。</div>
                    <div class="text1">２００１年理化学研究所遺伝子多型研究センター、アレルギー体質関連遺伝子研究チームリーダー（非常勤）兼務などを経て、２００８年統合医療をメインとしたクリニックを開設し、院長に就任しました。
                      ２０１８年からは、天裕会「のぞみクリニック」のがん治療専門医を兼務し、精力的に活動中です。</div>
                  </div>
                </div>
                
              </div>
            </div>
            
          </div>
          
        </div>
        <div class="bg_black">
          <div class="seperate_line"></div>
          <div style="padding-bottom: 30px; padding-top: 18px;">
            <div style="width: 100%;">
                <div class="m_side-text">
                    <div class="side-box">
                      <div class="side-line-highlight"></div>

                      <div class="title1">がんは怖くない</div>

                    </div>
                    <div class="content_text">
                      <div class="text1">日本で著名な末期がん治療専門医である白川太郎医師は、「ヨウ素水」を用いた総合療法で注目を集めています。</div>
                      <div class="text1">この療法により、世界の末期がん患者の平均3年生存率が15%であるのに対し、白川医師の治療を受けた患者の3年生存率は60%にまで向上しています。</div>
                      <div class="text1">この結果は、末期がん患者に対する希望を与える大きな成果とされています。現在までに、ヨウ素水を用いた総合治療を受けた患者は300例以上に達しています。</div>
                    </div>
                </div>
              <div class="m_side-text">
                <div class="side-box" style="width: 100%; justify-content: space-around;">
                  <img src="../../assets/departments/cancer/著作01.jpg" style="width: 150px;">
                  <img src="../../assets/departments/cancer/著作02.jpg" style="width: 150px;">
                </div>
                <div class="content1">
                  <div class="text1" style="text-align: center;">白川太郎医師の著作の一部</div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
        
        <div class="side-box" style="height: 120px; width: 100%; overflow: hidden;">
          <div style="position:absolute;width: 80%;left: 10%;">
            <div class=" m_slogan_large font_border">HOPE HIKARI（ヨウ素水）</div>
          </div>
        </div>

        <div class="img_banner" style="width: 100%; height:fit-content">
          <img style="width: 100%;" src="../../assets/departments/cancer/IMG_0054.png">               
        </div>

        <div class="bg_green" style="padding-top: 30px; padding-bottom: 30px;">
          <div class="m_side-text">
            <h1 class="slogan_big_l" style="color:#CBB8B2;">
              HOPE HIKARI（ヨウ素水)<br>について
            </h1>
          </div>

          <div class="content1">
            <div class="m_side-text">
              <h2 class="text1">「HOPE HIKARI」は、白川太郎医師と株式会社ユーアイナの共同研究と開発に基づくもので、がん治療において特別な効果が期待されるヨウ素水です。</h2>
            </div>
          </div>
          
          <div class="content1">
            <div class="m_side-text">
              
              <div>
                
                <div class="side-box">
                  <div class="side-line-highlight"></div>

                  <div class="title1">HOPE HIKARIの抗がんメカニズム</div>

                </div>
                <div class="content_text">
                  <div class="text1">１、直接作用：ヨウ素の高い酸化還元特性を利用して、ミトコンドリア介在のアポトーシスを誘発する。</div>
                  <div class="text1">・ミトコンドリア膜電位の破壊：ヨウ素が細胞に入り込むと、電子（e-）を放出し、がん細胞のミトコンドリア膜電位を破壊することで作用します。ミトコンドリア膜電位の低下は、細胞アポトーシスの重要なステップです。ミトコンドリア膜電位が低下すると、ミトコンドリアはアポトーシス促進因子を放出し、これがカスパーゼ経路を活性化し、最終的にがん細胞のアポトーシスを引き起こします。</div>
                  <div class="text1">・抗酸化作用：ヨウ素の抗酸化作用は、がん細胞が生成する過剰な活性酸素を除去し、局所的な微小環境の炎症を改善するのに役立ちます。また、正常な細胞を酸化ストレスから保護し、同時にがん細胞がアポトーシスに入ることを促進します。</div>
                </div>

                <div class="side-img">
                  <img src="../../assets/departments/cancer/碘元素作用01.webp" class="fit_img" >
                </div>
                
                <div class="content_text" >
                  <div class="text1">２、間接作用：細胞核信号伝達経路の調節</div>
                  <div class="text1">・6-ヨウ素内エステル（6-IL）の形成：ヨウ素は膜脂質であるアラキドン酸と反応して6-ヨウ素内エステル（6-IL）を形成します。これは抗腫瘍活性を持つ化合物です。6-ILは、腫瘍抑制遺伝子であるp53やp21の発現を誘導することができ、これらの遺伝子は細胞周期の調節に関与し、細胞分裂の停止や細胞のアポトーシスを誘導します。さらに重要なのは、6-ILがPPARγ（過酸化物質増殖物質活性化受容体γ）と結合してその機能を活性化することです。</div>
                  <div class="text1">・PPARγの活性化：PPARγは、細胞の代謝、分化、そしてアポトーシスにおいて非常に重要な役割を果たす核受容体です。活性化されたPPARγは、細胞の分化や増殖を抑制し、細胞のアポトーシスを促進するだけでなく、がんの上皮-間質転化（EMT）を阻止することもできます。</div>
                  <div class="text1">説明: 上皮-間質転化（Epithelial-Mesenchymal Transition, EMT）は、がん細胞が浸潤性と転移能力を獲得するための重要なプロセスです。EMTは、がん細胞が原発腫瘍部位から移動して他の組織に侵入することを可能にするだけでなく、さまざまな能力を与え、治療に対する抵抗性を高めます。</div>
              
                </div>

                
              </div>
              

            </div>

            
          </div>


          <div class="content1">
            <div class="m_side-text">
              <div>
                <div class="content_text">
                  <div class="text1">３、特異性：がん細胞への選択的な毒性</div>
                    <div class="text1">・正常細胞に対する低毒性：がん細胞は正常細胞に比べてアラキドン酸を高濃度で含んでいるため、ヨウ素の強力な抗がん活性は、膜脂質アラキドン酸と反応して6-ILを形成し、PPARγの機能を活性化することによって発揮されます。</div>
                    <div class="text1">このため、ヨウ素水は高い選択性を持ち、腫瘍細胞のEMT（上皮間葉転換）を阻止し、がん細胞のアポトーシス（細胞死）を誘導する一方で、正常細胞にはダメージを与えません。また、ヨウ素は人体の代謝においても重要な元素の一つであり、安定したヨウ素の摂取が安全かつ有効であることが示されています。この広範な適用性により、ヨウ素は非常に有望な抗がん剤として注目されています。</div>
                  
                </div>
                <div class="side-img">
                  <img src="../../assets/departments/cancer/碘元素作用02.webp" class="fit_img">
                </div>
              </div>
            </div>
          </div>

          <div class="content1">
            <div class="m_side-text" >
              <div class="side-box">
                <div class="side-line-highlight"></div>

                <div class="title1">未承認医薬品</div>

              </div>
              <div class="content_text">
                <div class="text1">HOPE HIKARI（ヨウ素水）は日本で医薬品医療機器等法上の承認を得ていない未承認医薬品です。</div>
                <div class="text1">日本の薬事法では、一般的な医薬品が市場に流通するには、薬品承認を取得し、大規模な薬理試験を実施した後に販売される必要があります</div>
                <div class="text1">しかし、市場に流通せず、特定の病院での治療や研究目的に限って使用される場合は、薬事法の制限を受けません。医療資格のある病院は、薬品の原材料、製造説明書、用途、治療方法および安全性について厚生省や保健所に報告するだけでよいです。各製造ロットごとに指定された機関の検査を受け、合格した場合にのみ院内処方や患者への使用が可能となります。また、使用対象者の名前や連絡先などの情報は、毎月1回保健所に報告する必要があります。薬品の品質に関する責任は、使用する病院およびその病院の主治医が負います。厚生省と保健所には監督と指導の責任があります。</div>
                <div class="text1">現在、HOPE HIKARI（ヨウ素水）は抗がん剤の新星として、その高い効果、低副作用、高い耐性により多くの患者の注目を集めています。日本独自の医療および薬品製造制度は、薬品の安全性と新薬開発の活発さを確保しています。</div>
              </div>
            </div>
          </div>
          
        </div>

      </div>

      <m-departs/>
      <m-footer/>
    </div>
  </div>
  </template>
  
  <script>
  import PageHeader from "@/components/PageHeader";
  import PageFooter from "@/components/PageFooter";
  import DepartmentAside from "@/components/DepartmentAside";
  import MTop from "../../components/m/MTop";
  import MFooter from "../../components/m/MFooter";
  import MDeparts from "../../components/m/MDeparts";
  import DietSidebar from "@/components/DietSidebar";
  export default {
    name: "Cancer",
    components: {MDeparts, MFooter, MTop, DepartmentAside, PageFooter, PageHeader,DietSidebar}
  }
  </script>
  
  <style scoped>
  /* PC */
  .hidden-sm-and-down .img {
    margin-top: 30px;
  }
  .detail1 {
    margin: 40px 20px;
  }
  .content1 {
    margin-top: 40px;
  }

  .content_text{
    margin-top: 18px;
    height: fit-content;
  }

  .title1 {
    color: #CBB8B2;
    font-size: 18px;
    margin-bottom: 10px;
  }
  .text1 {
    color: #ebebeb;
    line-height: 1.5;
    margin-bottom: 15px;
  }
  .other {
    color: #535353;
    font-size: 14px;
    line-height: 1.5;
  }
  
  .seperate_line{
    height:5px;
    border-top-color: #CBB8B2;
    border-top-width: 2px;
    border-top-style: solid;
    margin: 0 auto;
    width: 100%;
  }
  
  /* SP */
  .m-main {
    background-color: #FFFFFF;
    border-top: 2px solid #CBB8B2;
    border-bottom: 2px solid #CBB8B2;
    padding: 2rem 0;
  }
  .m-title {
    color: #535353;
    font-size: 24px;
    text-align: center;
    margin: 2rem 0;
  }
  .m-detail {
    background-color: #FFFFFF;
    padding: 0 1rem;
  }
  .m-subtitle {
    color: #CBB8B2;
    font-size: 18px;
    padding: 2rem 0 1rem;
  }
  .m-content {
    line-height: 24px;
    color: #535353;
  }
  
  .center-title{
    color: #535353;
    font-size: 24px;
    text-align: center;
    width: 100%;
    margin-bottom: 10px;
  }
  
  .LINE_btn{
    background-color: #20ce88;
    border-radius: 5px; 
    color: #FFFFFF;
    width:300px;
    height:50px;
    position: absolute;
    right: 0px;
    margin-right: 22%;
  }
  
  .banner_slogan{
  
    text-align: center;
    color: #326686;
    text-shadow: 1px 2px 0px #e9d5ce6b,
      0px 2px 3px #326686c4
      ;
    width: 100%;
  }
  
  .slogan_small{
    font-size: 15px;
    margin-top: 5px;
  }
  
  .slogan_big{
    font-size: 18px;
    margin-top: 15px;
  }
  
  .slogan_big_l{
    font-size: 30px;
    margin-top: 15px;
  }
  
  .slogan_large{
    font-size: 50px;  
  }
  
  .m_slogan_small{
    font-size: 5px;
    margin-top: 5px;
  }
  
  .m_slogan_big{
    font-size: 10px;
    margin-top: 5px;
  }
  
  .m_slogan_big_l{
    font-size: 12px;
    margin-top: 5px;
  }
  
  .m_slogan_large{
    font-size: 20px;  
  }
  
  .font_border{
    text-align: center;
    color: #326686;
    text-shadow: 
      0px 1px 4px #f5efe3,
      0px -1px 4px #f5efe3,
      1px 0px 4px #f5efe3,
      -1px 0px 4px #f5efe3;
    width: 100%;
  }
  
  .img_banner{
    position: relative;
    height: auto;
  }
  
  .table_ryoukin{
    background-color: #ebf4fd;
    color: #535353;
    width: 100%;
    margin: 0 auto;
    border-collapse:collapse;
  }
  
  .table_ryoukin tr{
    height:60px
  }
  
  .ryoukin_name_top{
    font-size: 24px;
    text-align: center;
  }
  
  .ryoukin_name{
    vertical-align: middle;
    border-bottom-color: #8A9297;
    border-bottom-width: 2px;
    border-bottom-style: solid;
  
    
  }
  
  .bg_black{
    background-color: #4d4f4f;
    
    width: 100%;
    height:auto;

  }
  
  .bg_blue{
    background-color: #326686;
    width: 100%;
    height: auto;
  }
  
  .bg_green{
    background-color: #5a7977;
    width: 100%;
    height: auto;
  }
  
  .m_bg_black{
    background-color: #4d4f4f;
    width: 100%;
    height:fit-content;
    padding-bottom: 100px;
  }
  
  .m_bg_blue{
    background-color: #326686;
    width: 100%;
    height: auto;
    padding-top: 10px;
    padding-bottom: 50px;
  }
  
  .m_bg_green{
    background-color: #5a7977;
    width: 100%;
    height: auto;
    padding-top: 10px;
    padding-bottom: 50px;
  }
  
  .ryoukin_hint{
    color: #F71B1B;
    font-size: 16px;
    text-align: right;
    padding-right: 50px;
  }
  
  .title {
    font-size: 30px;
    letter-spacing: 5px;
    padding-top: 20px;
    padding-left: 20px;
  }
  
  .m_title {
    font-size: 20px;
    letter-spacing: 5px;
    padding-left: 20px;
  }
  
  .separator {
    color: #CBB8B2;
    width: 100%;
    margin: 0 auto;
    border-top: 4px solid;
    border-bottom: 4px solid;
  }
  
  .side-img{
  height: auto;
  width:auto;
  vertical-align:middle;
  margin-left: 30px;
  margin-right: 30px;
  }

  .side-img-flex{
    overflow: hidden;
    position: relative;
  }
  
  .side-img-flex .content{
    position:absolute; 
    left: 50%; 
    transform: translate(-50%);
  }

  .side-text{
  height: fit-content;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: auto;
  }
  .m_side-text{
  height: fit-content;
  margin-left: 7%;
  margin-right: 7%;
  margin-bottom: auto;
  width: 86%;
  }
  
  .side-text-line{
    height: fit-content;
  }
  
  
  
  .side-box{
    display: inline-flex;
    justify-content: space-between;
    align-items:center;
    vertical-align: top;
    height: auto;
  }

  .side-box-img{
    display: inline-flex;
    justify-content: space-between;
    align-items:top;
    vertical-align: top;
    height: auto;
  }

  .fit_img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .side-line-highlight{
    background-color: #CBB8B2;
    width:6px;
    height: 25px;
    margin-bottom: 10px;
    margin-right: 10px;
  }
  
  </style>
  